import { useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import {
  Route,
  Routes,
  Navigate,
  useLocation,
  useSearchParams
} from 'react-router-dom'

import HireStars from '../pages/HireStars'
import Demographics from '../pages/Demographics'
import Pathways from '../pages/Pathways'

import { NATIONAL_CODE, PERPETUATED_PARAMS } from '../constants/globalKeys'

import { getParamsObj } from '../util/util'

import LinearProgress from '@mui/material/LinearProgress'
import { useTheme } from '@mui/material'
import BreadcrumbNavigation from 'src/components/BreadcrumbNavigation'
import { Region } from 'src/interfaces/demographics'
import { useMsaRegionSelection } from 'src/hooks/useMsaRegionSelection'
import { useNavigationHistories } from 'src/providers/NavHistoriesContext'

function AppWrapper() {
  const { pathname } = useLocation()
  const location = useLocation()

  const theme = useTheme()
  const {
    msaSelectedRegion,
    handleMsaRegionSelection,
    isLoading,
    jobSearchLoading,
    jobOptions,
    jobSelection,
    setJobSelection
  } = useMsaRegionSelection()

  const [msaRegion, setMsaRegion] = useState<any>(null)
  const [searchParams, setSearchParams] = useSearchParams()
  const { navHistoriesValue, setNavHistoriesValue } = useNavigationHistories()
  const { getAccessTokenSilently } = useAuth0()

  const api_url = process.env.REACT_APP_API_URL

  useEffect(() => {
    window.dataLayer.push({ event: 'Page Loaded' })
    window.scrollTo(0, 0)
  }, [pathname])

  useEffect(() => {
    const fetchData = async () => {
      const accessToken = await getAccessTokenSilently()
      fetch(api_url + '/region', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        }
      })
        .then((resp) => resp.json())
        .then((msaRegion) => {
          const sortedMsaRegion = msaRegion.sort((a: any, b: any) => {
            if (a.region_code === NATIONAL_CODE) return -1
            if (b.region_code === NATIONAL_CODE) return 1

            if (a.region_name_short < b.region_name_short) return -1
            if (a.region_name_short > b.region_name_short) return 1
            return 0
          })

          setMsaRegion(sortedMsaRegion)
        })
    }
    fetchData()
  }, [api_url, getAccessTokenSilently])

  const handleRegionSelection = (
    msaRegion: Region[] | undefined,
    searchParams: URLSearchParams,
    handleMsaRegionSelection: (
      selected_region: any,
      options?: { updateParams: boolean }
    ) => void
  ) => {
    if (msaRegion && searchParams) {
      const regionParam = searchParams.get('region')?.toLowerCase()
      const regionParam2 = searchParams.get('geo_type_id')?.toLowerCase()
      const regionParam3 = searchParams.get('region_code')

      const foundRegion = msaRegion.find(
        (region: Region) =>
          (region.region_name.toLowerCase() === regionParam ||
            region.region_name_short?.toLowerCase()) &&
          region.geo_type_id === parseInt(regionParam2 ?? '') &&
          region.region_code === regionParam3
      )

      if (foundRegion) {
        // since we got the region from the params, we don't want to update them when we update state
        handleMsaRegionSelection(foundRegion, { updateParams: false })
      } else {
        const nationalRegion = msaRegion.find(
          (regionObj: Region) =>
            regionObj.region_name.toLowerCase() === NATIONAL_CODE
        )
        handleMsaRegionSelection(nationalRegion)
      }
    }
  }
  useEffect(() => {
    handleRegionSelection(msaRegion, searchParams, handleMsaRegionSelection)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [msaRegion, pathname])

  useEffect(() => {
    handleRegionSelection(msaRegion, searchParams, handleMsaRegionSelection)

    if (
      navHistoriesValue.some(
        (item: { geo_type_id: any }) => item.geo_type_id === undefined
      )
    ) {
      setNavHistoriesValue(
        navHistoriesValue.filter(
          (item: { geo_type_id: any }) => item.geo_type_id !== undefined
        )
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, location])

  function handleJobSelection(selectedJob: any, { resetSkills = false } = {}) {
    setJobSelection(selectedJob)

    let newParams
    if (resetSkills) {
      // if we are resetting skills remove all but perpetuated parameters
      newParams = getParamsObj(searchParams, PERPETUATED_PARAMS)
    } else {
      newParams = getParamsObj(searchParams)
    }

    //@ts-ignore
    setSearchParams({
      ...newParams,
      job_title: selectedJob?.job_title.toLowerCase()
    })
  }

  if (isLoading) {
    return (
      <div
        style={{
          height: '100vh',
          width: '100wh',
          backgroundColor: theme?.palette.secondary.main
        }}
      >
        <LinearProgress />
      </div>
    )
  }

  return (
    <div>
      <BreadcrumbNavigation
        handleRegionChange={(selected_region: any) =>
          handleMsaRegionSelection(selected_region)
        }
        msaRegion={msaRegion}
        searchParams={searchParams}
      />
      <Routes>
        <Route
          path='/find-stars'
          element={
            <Demographics
              msaSelectedRegion={msaSelectedRegion}
              msaRegion={msaRegion}
              handleRegionChange={(
                selected_region: any,
                updateParams?: boolean
              ) => handleMsaRegionSelection(selected_region, { updateParams })}
              key={location.search}
            />
          }
        />

        <Route
          path='/match-skills'
          element={
            <Pathways
              msaSelectedRegion={msaSelectedRegion}
              jobSearchLoading={jobSearchLoading}
              jobOptions={jobOptions}
              jobSelection={jobSelection}
              handleJobSelection={handleJobSelection}
            />
          }
        />

        <Route
          path='/hire'
          element={
            <HireStars
              msaSelectedRegion={msaSelectedRegion}
              jobSearchLoading={jobSearchLoading}
              jobOptions={jobOptions}
              jobSelection={jobSelection}
              handleJobSelection={handleJobSelection}
              msaRegion={msaRegion}
            />
          }
        />
        <Route
          path='*'
          element={<Navigate to='/find-stars' key={location.search} />}
        />
      </Routes>
    </div>
  )
}

export default AppWrapper
